<template>
  <div class="modal" :class="{'is-active':show}">
    <div class="modal-background"></div>
    <div class="modal-card has-text-left">
      <header class="modal-card-head">
        <p class="modal-card-title is-size-4 is-size-5-mobile">{{(node.mac)?node.mac:"Create Node"}}</p>
        <button class="delete" @click="$emit('close')"></button>
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="submit">
          <button type="submit" ref="save_edit_node" hidden></button>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Mac</label>
            </div>
            <div class="field-body">
              <div class="field has-addons">
                <p class="control" v-if="!node.mac">
                  <span class="select">
                    <select v-model="input.type" required>
                      <option value="" disabled>Model</option>
                      <option value="1">TNJ_SM- </option>
                      <option value="2">TNJ_SC- </option>
                      <option value="3">TNJ_SI- </option>
                    </select>
                  </span>
                </p>
                <p class="control" v-else>
                  <span class="input is-info has-text-info" v-if="input.type==1">TNJ_SM- </span>
                  <span class="input is-info has-text-info" v-if="input.type==2">TNJ_SC- </span>
                  <span class="input is-info has-text-info" v-if="input.type==3">TNJ_SI- </span>
                </p>
                <p class="control is-expanded">
                  <input class="input" type="text" placeholder="Mac Address" v-model="input.mac" required>
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Name</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input class="input" type="text" placeholder="Node's Name" v-model="input.name" required>
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Detail</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded has-icons-left">
                  <textarea class="textarea has-fixed-size" placeholder="Node's Detail" v-model="input.detail" required></textarea>
                </p>
              </div>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <div class="w-100">
          <button type="button" class="button is-small is-link float-right mx-1" @click="$refs.save_edit_node.click()">Save</button>
          <button type="button" class="button is-small is-default float-right mx-1" @click="$emit('close')">Cancel</button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalNode',
  props: {
    show: Boolean,
    node: Object,
  },
  data(){
    return {
      input: { type: "" },
    }
  },
  methods:{
    set_input(){
      this.input.id = this.node.id;
      this.input.type = this.node.type;
      this.input.mac = this.node.mac;
      this.input.name = this.node.name;
      this.input.detail = this.node.detail;
    },
    submit(){
      this.$emit('submit',this.input);
    }
  },
  watch:{
    show(){
      this.input = { type: "" };
      if(this.node.id)this.set_input();
    }
  }
}
</script>
