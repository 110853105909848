<template>
  <div id="branch">
    <div class="card">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-5">
          <i class="fas fa-charging-station"></i>
          {{branch.name}}
          <button type="button" class="button is-small is-link" style="float:right;" @click="new_node=true">
            <span class="mr-2 is-hidden-mobile">เพิ่มอุปกรณ์ใหม่</span>
            <i class="fas fa-plus"></i>
          </button>
        </strong>
        <hr>
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <small class="is-size-7 is-hidden-mobile" style="float:right;">(จำนวนอุปกรณ์: {{Nodes.length}} เครื่อง)</small>
          <ul>
            <li><a @click="redirect('/member/mornitor')">สาขา</a></li>
            <li class="is-active"><a>{{branch.name}}</a></li>
          </ul>
        </nav>
        <div class="has-text-centered" v-if="!Nodes.length">
          <hr>ไม่พบข้อมูลอุปกรณ์ โปรดเพิ่มอุปกรณ์ใหม่
        </div>
        <div class="columns is-multiline" v-else>
          <div class="column is-one-third-desktop" v-for="(val,key) in Nodes" :key="key">
            <article class="message is-link">
              <div class="message-header py-1">
                <p class="w-100 cursor_pointer" @click="redirect('/member/node/'+val.id)">
                  <i class="fas fa-code-branch"></i>
                  {{val.name}} ({{val.mac}})
                </p>
                <div class="dropdown is-right is-hoverable">
                  <div class="dropdown-trigger">
                    <button class="button is-xs">
                      <i class="fas fa-chevron-down"></i>
                    </button>
                  </div>
                  <div class="dropdown-menu">
                    <div class="dropdown-content">
                      <a class="dropdown-item" @click="node=val; new_node=true;">
                        แก้ไขข้อมูล
                      </a>
                      <hr class="dropdown-divider">
                      <a class="dropdown-item has-text-danger" @click="remove_node(val)">
                        ลบข้อมูล
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="message-body has-text-left py-4 cursor_pointer" @click="redirect('/member/node/'+val.id)">
                <small class="is-size-6 is-size-7-mobile">{{val.detail}}</small>
              </div>
          </article>
        </div>
        </div>
      </div>
    </div>

    <modal-node :show="new_node" :node="node" @submit="submit_node" @close="new_node=false; node={};"></modal-node>

  </div>
</template>

<script>
import ModalNode from '@/components/Modal/Node'

export default {
  name: 'MemberBranch',
  components:{ ModalNode },
  props: {
    loaded: Object,
    branchs: Array,
    nodes: Array,
  },
  data(){
    return {
      b_id: this.$route.params.id,
      branch: {},
      new_node: false,
      node: {},
    }
  },
  created(){
    const INDEX = this.branchs.map((b) => parseInt(b.id)).indexOf(parseInt(this.b_id));
    if(INDEX!==-1){
      this.branch = this.branchs[INDEX];
    }else{
      this.redirect("/member/mornitor")
    }
  },
  mounted(){
    // console.log(this.loaded.get_node);
    if(!this.loaded.get_node){
      this.$emit('get_node',this.b_id);
    }else{
      const INDEX_ = this.loaded.get_node.map((n_id) => parseInt(n_id)).indexOf(parseInt(this.b_id));
      if(INDEX_==-1){
        this.$emit('get_node',this.b_id);
      }
    }
  },
  methods:{
    submit_node(input){
      if(!input.id){
        const INDEX = this.nodes.map((n) => n.mac).indexOf(input.mac);
        if(INDEX!==-1){
          this.$swal({
            title: "ไม่สามารถเพิ่มอุปกรณ์ได้!",
            text: "คุณมีอุปกรณ์หมายเลขนี้อยู่แล้ว \n (Mac Address: "+input.mac+")",
            className: "text-center",
            icon: "warning",
          });
        }else{
          this.submit_new_node(input);
          this.new_node = false;
        }
      }else{
        if(input.mac!==this.node.mac){
          this.$swal({
            title: "เปลี่ยนหมายเลขอุปกรณ์?",
            text: "คุณแน่ใจหรือไม่ว่าต้องการเปลี่ยนหมายเลขอุปกรณ์นี้ \n\n (หมายเลขเดิม: "+this.node.mac+") \n (หมายเลขใหม่: "+input.mac+")",
            className: "text-center",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
              this.submit_edit_node(input);
              this.new_node = false;
            }
          });
        }else{
          this.submit_edit_node(input);
          this.new_node = false;
        }
      }
    },
    async submit_new_node(input){
      var url = this.api+'node/add/'+this.secret;
      var data = new FormData();
      data.set('b_id', this.b_id);
      data.set('mac', input.mac);
      data.set('name', input.name);
      data.set('detail', input.detail);
      data.set("secret", this.secret);
      await this.Axios.post(url, data)
      .then((response)=>{
        // console.log(response.data);
        if(response.data.error==0){
          this.nodes.push(response.data.data);
          this.$swal("","เพิ่มอุปกรณ์ใหม่","success");
        }else{
          this.$swal("",response.data.data,"warning");
        }
      })
      .catch((error)=>{
        this.$swal("","","warning");
        console.error(error);
      })
    },
    async submit_edit_node(input){
      var url = this.api+'node/save/'+this.secret;
      var data = new FormData();
      Object.keys(input).forEach((key) => {
        data.set(key, input[key]);
      });
      data.set("secret", this.secret);
      await this.Axios.post(url, data)
      .then((response)=>{
        // console.log(response.data);
        if(response.data.error==0){
          Object.keys(input).forEach((key) => {
            this.node[key] = input[key];
          });
          this.node = {};
          this.$swal("","Successful Save","success");
        }else{
          this.$swal("",response.data.data,"warning");
        }
      })
      .catch((error)=>{
        this.$swal("","","warning");
        console.error(error);
      })
    },
    remove_node(val){
      this.$swal({
        title: "ลบอุปกรณ์?",
        text: "ชื่ออุปกรณ์: "+val.name+"\n (Mac Address: "+val.mac+")",
        className: "text-center",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          this.$swal({
            title: "คุณแน่ใจหรือไม่?",
            text: "ต้องการลบอุปกรณ์นี้ \n (Mac Address: "+val.mac+")",
            className: "text-center",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then(async (willDelete) => {
            if (willDelete) {
              var url = this.api+'node/delete/'+this.secret;
              var data = new FormData();
              data.set("id", val.id);
              data.set("b_id", val.b_id);
              data.set("secret", this.secret);
              await this.Axios.post(url, data)
              .then((response)=>{
                // console.log(response.data);
                if(response.data.error==0){
                  const INDEX = this.nodes.map((n) => parseInt(n.id)).indexOf(parseInt(val.id));
                  this.nodes.splice(INDEX, 1);
                  this.$swal("ลบอุปกรณ์ "+val.name, "(Mac Address: "+val.mac+")", "success");
                }else{
                  this.$swal("",response.data.data,"warning");
                }
              })
              .catch((error)=>{
                this.$swal("","","warning");
                console.error(error);
              })
            }
          });
        }
      });
    }
  },
  computed:{
    Nodes(){
      return this.nodes.filter(node => { return parseInt(node.b_id)==parseInt(this.b_id) });
    }
  }
}
</script>
